<template>
  <v-container class="pa-4 text-center">
    <PageTitle text="Clinical Supervision" />
    <PageText :text="p1" />
    <PageQuote :text="q1" person="Brene Brown" />
    <PageImage path="img/calm_tea.jpg" />
  </v-container>
</template>

<script>
import PageText from '@/components/PageText.vue';
import PageTitle from '@/components/PageTitle.vue';
import PageQuote from '@/components/PageQuote.vue';
import PageImage from '@/components/PageImage.vue';

export default {
  components: {
    PageText, PageImage, PageTitle, PageQuote,
  },
  data: () => ({
    p1: `
     I am a registered clinical supervisor in the state of Idaho. For new therapists and social
     workers seeking independent licensure, or therapists desiring supervision to assure their
     quality of care, I provide availability for supervision on my weekly schedule. I have
     supervised graduate-level interns and LMSWs and enjoy fostering professional development in
     others, teaching and mentoring. I use an integrative approach to supervision including
     humanistic and developmental models. I would be an ideal supervisor for those seeking someone
     who is primarily a clinician rather than an administrator. 
    `,
    q1: `
      “Connection is the energy that exists between people when they feel seen, heard, and valued;
      when they can give and receive without judgment; and when they derive sustenance and strength
      from the relationship.” 
    `,
  }),
};
</script>
